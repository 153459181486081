<template>
    <comp-table title="商品分类" edit-key="catId" :columns="columns" :table-api="tableApi" :deleteApi="getDeleteUrl" :form-component="dataForm">
        <template v-slot:search="data">
            <Select v-model="data.search.supplierId" filterable style="width: 300px" transfer placeholder="请选择供应商">
                <Option v-for="item in suppliers" :value="item.supplierId" :key="item.supplierId">{{ item.supplierName }}</Option>
            </Select>
        </template>
    </comp-table>
</template>

<script>
import DataForm from "./form.vue"
import CompTable from "../../jointly/components/comp-table.vue"

export default {
    components: { CompTable },

    data() {
        return {
            dataForm: DataForm,
            // 供应商列表
            suppliers: null,
            // 表格接口
            tableApi: null,

            columns: [
                {
                    title: "分类名称",
                    key: "catName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "分类图标",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return params.row.catPic
                            ? h("img", {
                                  style: {
                                      width: "30px",
                                      height: "30px",
                                  },
                                  attrs: {
                                      src: params.row.catPic,
                                  },
                              })
                            : h("p", null, "-")
                    },
                },
                {
                    title: "排序",
                    key: "sortOrder",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "下单必选",
                    key: "orderRequired",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {},
                            params.row.orderRequired == 1
                                ? "必选" 
                                : '非必选'
                        )
                    },
                },
                {
                    title: "启禁用",
                    key: "isShowDesc",
                    minWidth: 200,
                    align: "center",
                },
            ],
        }
    },

    mounted() {
        this.getSupplierList()
    },

    methods: {
        getSupplierList() {
            this.$get("/gateway/apps/o2o/api/o2o/user/selectUserHasSupplier", {
                oemCode: parent.vue.oemInfo.oemCode,
            }).then(res => {
                if (res.code == 200) {
                    const data = res.dataList
                    // 供应商列表
                    this.suppliers = data

                    if (data && data.length > 0) {
                        this.tableApi = `/gateway/apps/o2o/api/o2o/admin/getSupplierCategoryByPage?appCode=shop&oemCode=${parent.vue.oemInfo.oemCode}&supplierId=${data[0].supplierId}`
                    } else {
                        this.$Modal.warning({ title: "温馨提示", content: "当前账号没有绑定供应商，请先前往绑定！" })
                    }
                }
            })
        },

        getDeleteUrl(v) {
            return `/gateway/apps/o2o/api/o2o/admin/deleteSupplierCategory?catId=${v.catId}&oemCode=zjsm&custGlobalId=${parent.vue.loginInfo.userinfo.custGlobalId}&appCode=shop&supplierId=${v.supplierId}`
        },
    },
}
</script>
<style lang="less"></style>
